import { Directive, ElementRef, inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { LanguageService } from '@app/i18n';
import { environment } from '@env/environment';
import { Subscription, tap } from 'rxjs';

@Directive({
    selector: '[appRegulations]',
    standalone: true,
})
export class RegulationsDirective implements OnInit, OnDestroy {
    private readonly elementRef = inject(ElementRef);
    private readonly renderer2 = inject(Renderer2);
    private readonly languageService = inject(LanguageService);

    private readonly subscriptions$ = new Subscription();

    ngOnInit() {
        this.subscriptions$.add(this.languageService.language$.pipe(tap((lang) => this.update(lang))).subscribe());
    }

    ngOnDestroy() {
        this.subscriptions$.unsubscribe();
    }

    private update(lang: string) {
        const languageConfig = environment.supportedLanguages.find((language) => language.key === lang);
        const defaultConfig = environment.supportedLanguages.find((language) => language.key === environment.defaultLanguage);
        if (languageConfig && languageConfig.regulations) {
            this.renderer2.setAttribute(this.elementRef.nativeElement, 'href', languageConfig.regulations);
        } else {
            this.renderer2.setAttribute(this.elementRef.nativeElement, 'href', <string>defaultConfig?.regulations);
        }
    }
}
